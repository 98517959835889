<template>
	<b-overlay :show="loading" rounded="sm">
		<!-- 1 -->
		<b-card no-body class="mb-4">
			<!-- Search Control -->
			<b-card-body class="p-3 d-flex justify-content-between">
				<b-form-group class="mb-2 w-lg-25">
					<b-input-group>
						<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"
							@input="debouncedSearch"></b-form-input>

						<b-input-group-append>
							<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<!-- <div class="d-flex align-items-center">
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-center>Add</b-button>
                        <b-button variant="secondary">Print</b-button>
                    </div> -->
			</b-card-body>
			<!-- End Section -->

			<!-- Data Table -->
			<b-table show-empty responsive no-local-sorting class="mb-0" head-variant="light" :items="customers" :fields="fields" hover
				@row-clicked="onRowClicked" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
				<template #cell(id)="data"> #{{ data.item.id }} </template>
				<template #cell(origin)="data">{{ data.item.originDescription }}</template>
				<template #cell(isActive)="data">
					<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
					<b-badge variant="danger" v-else>Inactive</b-badge>
				</template>
				<template #cell(action)>
					<b-button variant="outline-danger" pill class="d-flex align-items-center mr-0">
						<feather type="trash" class="feather-sm"></feather>
					</b-button>
				</template>
			</b-table>
			<!-- End Section -->

			<!-- Pagination -->
			<div class="p-3 d-md-flex align-items-center">
				<b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
					label-cols-lg="9" label-size="sm" label-class="fw-medium" class="mb-0">
					<b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"
						@input="getData"></b-form-select>
				</b-form-group>
				<div class="ml-auto mt-3 mt-md-0">
					<b-pagination @input="getData" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
						align="fill" class="my-0"></b-pagination>
				</div>
			</div>
			<!-- End Section -->
		</b-card>

		<b-modal id="modal-center" hide-footer centered title="Add a new comment">
			<b-form>
				<b-form-group label="First Name" label-class="fw-medium mb-1" label-for="firstName">
					<b-form-input id="firstName" type="text" placeholder="Your name here"></b-form-input>
				</b-form-group>

				<b-form-group label="Last Name" label-class="fw-medium mb-1" label-for="lastName">
					<b-form-input id="lastName" type="text" placeholder="Your last name here"></b-form-input>
				</b-form-group>

				<b-form-group label="Address" label-class="fw-medium mb-1" label-for="address">
					<b-form-input id="address" type="text" placeholder="Your address here"></b-form-input>
				</b-form-group>

				<b-form-group label="Type" label-class="fw-medium mb-1" label-for="type">
					<b-form-select :options="options" id="type" type="text"
						placeholder="Please select an option"></b-form-select>
				</b-form-group>

				<b-form-group label="Global ID" label-class="fw-medium mb-1" label-for="globalId">
					<b-form-input id="globalId" type="number" placeholder="0000"></b-form-input>
				</b-form-group>
				<b-button block variant="primary">Save Customer</b-button>
			</b-form>
		</b-modal>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from 'lodash';
export default {
	name: "Customers",

	data: () => ({
		page: {
			title: "SeachTable",
		},       
		sortBy: '',
        sortDesc: false,
		filter: '',
		sortColumns: {
			id: '[Profiles].[Id]',
			reasonTypeDescription: '[ReasonTypes].[Name]',
			firstName: '[Profiles].[FirstName]',
			lastName: '[Profiles].[LastName]',
			address1: '[Profiles].[Address1]',
			origin: '[Profiles].[Origin]'
		},
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "lastName",
				label: "Last Name",
				sortable: true,
			},
			{
				key: "firstName",
				label: "First Name",
				sortable: true,
			},
			{
				key: "address1",
				label: "Adreess",
				sortable: true,
			},
			{
				key: "reasonTypeDescription",
				label: "Customer Type",
				sortable: true,
			},
			{
				key: "origin",
				label: "Origin",
				sortable: true,
			},
			{
				key: "isActive",
				label: "Status",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],

		options: [
			{ value: null, text: "Please select an option" },
			{ value: "person", text: "Person" },
		],

		loading: false,
		debouncedSearch: null
	}),
	components: {},
	methods: {
		onRowClicked(item) {
			this.$router.push(`/customers/detail/${item.id}`);
		},
		async getData() {
			const sortDirection = this.sortDesc ? 'desc' : 'asc'
			const sortProperty = this.sortColumns[this.sortBy] ?? ''
			let status = null;
			if (this.$route.query.status == "active") status = true;
			if (this.$route.query.status == "inactive") status = false;

			this.loading = true;
			await this.$store.dispatch("customers/get_allCustomers", { skip: this.currentPage, take: this.perPage, status, query: this.filter, sortDirection, sortProperty  });

			this.totalRows = this.totalCustomers;
			this.loading = false;
		},
	},
	computed: {
		...mapGetters({
			customers: "customers/getCustomers",
			totalCustomers: "customers/getTotalCustomers",
		}),
	},

	watch: {
		"$route.query.status"() {
			this.getData();
		},
		"sortBy"() {
			this.getData()
		},
		"sortDesc"() {
			this.getData()
		}
	},
	mounted() { },
	created() {
		this.debouncedSearch = debounce(this.getData, 300);
		this.getData()
	},
};
</script>
